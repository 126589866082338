/* eslint-disable max-len */
import React from 'react';
import Helmet from 'react-helmet';
import wrapInSection from '../components/wrapInSection';
import wrapInLayout from '../components/wrapInLayout';

const Methodology = () => (
  <div className="landing">
    <Helmet title="КЭСПА – Методика" />

    <div className="method-wrapper page-wrapper narrow-container">
      <h1>Методика</h1>

      <p>
        Что происходит, когда вы стараетесь поддержать разговор по-английски во
        время деловой или личной встречи? Каждая ваша мысль наталкивается на
        один и тот же панический вопрос:
      </p>
      <p className="central">Как Это Сказать По-Английски???</p>
      <p>
        Чтобы ответить на этот вопрос, нужно, чтобы самые важные грамматические
        конструкции английского языка и наиболее употребительные слова,
        необходимые именно вам, хранились не в словарях и справочниках, и, что
        самое удивительное, даже не в вашей голове, а{' '}
        <span className="emphasized">в губах, зубах и на языке</span>, то есть
        именно так, как это происходит, когда вы говорите по-русски. Чтобы
        достичь такой же беглости в речи на английском языке, вам нужно четко
        знать (так, чтоб от зубов отскакивало) как сказать по-английски то, из
        чего состоит речь среднестатистического образованного современного
        человека.
      </p>

      <h2>Как мы этого добиваемся?</h2>

      <ol className="marked">
        <li>
          Для эффективности обучения мы предложили изменить направление, в
          котором традиционно начинается процесс изучения иностранного языка, на
          прямо противоположное, и расширять знание грамматики и лексики
          английского языка, отталкиваясь не от{' '}
          <span className="emphasized">чужого английского</span>, а от
          привычного <span className="emphasized">родного русского</span>.
        </li>
        <li>
          <p>
            Мы придумали новую единицу – единицу структурирования речи – и
            назвали ее{' '}
            <span className="emphasized">прагмалингвистическая формула</span>{' '}
            (прагмалингвистика – это наука, которая изучает речь).
          </p>
          <p>
            Прагмалингвистическая формула (ПЛФ) – это жесткая грамматическая
            конструкция со сменной смысловой составляющей, которую принято
            употреблять в определенной жизненной ситуации.
          </p>
          <p>
            В функции сменной смысловой составляющей ПЛФ может выступать любая
            часть речи. Вот несколько примеров ПЛФ, сменными смысловыми
            составляющими которых является глагол, то есть часть речи,
            обозначающая действие:
          </p>

          <ul>
            <li>
              <span className="russian">Я умею делать это.</span> (читать,
              писать, говорить по-английски, водить машину…)
            </li>
            <li>
              <span className="russian">Ты всегда делаешь это!</span>{' '}
              (опаздываешь, забываешь ключи, портишь мне настроение…)
            </li>
            <li>
              <span className="russian">Он уже сделал это.</span> (пришел,
              пообедал, позвонил маме, поругался с женой…)
            </li>
          </ul>

          <p>
            А вот примеры ПЛФ, позволяющие выражать{' '}
            <span className="emphasized">отношение</span> говорящего человека к
            какому-нибудь <span className="emphasized">действию</span>:
          </p>
          <ul>
            <li>
              <span className="russian">Ты должен сделать это.</span> (написать,
              приготовить, понять…) – эта ПЛФ выражает необходимость
              определенного действия.
            </li>
            <li>
              <span className="russian">Тебе нельзя делать это.</span> (курить,
              подслушивать, опаздывать…) – эта ПЛФ запрещает какое-то конкретное
              действие.
            </li>
            <li>
              <span className="russian">Неужели ты уже сделал это?!</span>{' '}
              (написал, приготовил, понял…)?! – эта ПЛФ выражает удивление перед
              фактом, что какое-то действие уже произошло.
            </li>
          </ul>
        </li>

        <li>
          Речь среднестатистического русскоязычного человека мы представили в
          виде коммуникативного набора прагмалингвистических формул (их около
          300). Именно к ним мы и задаем вопрос{' '}
          <i>«Как Это Сказать По-Английски?»</i>, который в рамках нашей
          методики перестает звучать панически, а наоборот, становится
          действенным инструментом обучения. Отвечая на этот вопрос на каждом
          занятии, вы незаметно для себя осваиваете грамматику и лексику
          английского языка.
        </li>
        <li>
          Для того чтобы весь{' '}
          <span className="emphasized">коммуникативный набор</span> от зубов
          отскакивал, мы разработали особую систему{' '}
          <span className="emphasized">
            артикуляционно-грамматических упражнений
          </span>
          . Чтобы эти упражнения приносили ощутимый и быстрый результат, мы
          предлагаем подходить к ним творчески и импровизировать, подставляя в
          изучаемую формулу известные к данному уроку слова и идиомы, оставаясь,
          однако, в рамках изучаемой на данном конкретном занятии грамматической
          конструкции. Таким образом, мы убиваем сразу двух зайцев: закрепляя
          грамматику, осваиваем новую лексику
        </li>
        <li>
          Под каждого ученика мы выстраиваем особый, подходящий именно ему режим
          обучения, с превалированием того или иного типа тренингов и изучением
          именно той лексики, которая необходима ему для решения конкретных
          задач.
        </li>
      </ol>

      <h2>Результаты</h2>
      <p>
        Методика КЭСПА дает эффективное и гибкое, активное знание английского
        языка. Вы сможете использовать его в любых целях и в дальнейшем, при
        необходимости, легко развивать свое знание английского в любом
        направлении. Мы можем дать вам тот фундамент, который позволит вам
        максимально эффективно и легко строить именно ваш английский язык.
        Гибкость и одновременно прочность этого фундамента – залог вашего успеха
        в применении английского языка в любых ситуациях. Знания и умения,
        которые мы даем, – это не косная система заученных предложений и
        абзацев, а эффективный инструмент общения, который позволит вам не
        попадать в языковой тупик в дальнейшем. В какой бы сложной языковой
        ситуации вы ни оказались, вы всегда сможете найти выход.
      </p>
    </div>
  </div>
);

export default wrapInLayout(wrapInSection(Methodology));
